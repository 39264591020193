
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "EquimpentGroupSelect",
  props: {
    equipment_group_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:equipment_group_id", "changeGroup"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const inputEquipmentGroup = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isEquipmentGroupRequired = (value) => {
      if (props.required && !value) {
        return t("requipmentgroup");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "equipmentGroup",
      isEquipmentGroupRequired
    );

    const getEquipmentGroup = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/equipment_groups/lists").then(({ data }) => {
        inputEquipmentGroup.value.list = data.equipment_groups;
        inputEquipmentGroup.value.options = data.equipment_groups;
      });
    };
    const selectEquipmentGroup = (query) => {
      if (query !== "") {
        inputEquipmentGroup.value.loading = true;
        setTimeout(() => {
          inputEquipmentGroup.value.loading = false;
          inputEquipmentGroup.value.options =
            inputEquipmentGroup.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputEquipmentGroup.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/equipment_groups/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputEquipmentGroup.value.list = data.equipment_groups;
              inputEquipmentGroup.value.options = data.equipment_groups;
            });
          }
        }, 200);
      } else {
        inputEquipmentGroup.value.options = [];
      }
    };

    watch(
      () => props.equipment_group_id,
      (first) => {
        element_id.value = first;
        const selected = inputEquipmentGroup.value.list.find(
          (x) => x.id === first
        );
        if (selected) {
          emit("changeType", selected.code);
        }
        if (
          first != null &&
          !inputEquipmentGroup.value.options.map((x) => x.id).includes(first) &&
          inputEquipmentGroup.value.options.length > 0
        ) {
          ApiService.get("/api/equipment_groups/" + first).then(({ data }) => {
            inputEquipmentGroup.value.options.push(data);
          });
        }
      }
    );

    onMounted(() => getEquipmentGroup());

    return {
      element_id,
      inputEquipmentGroup,
      errorMessage,
      getEquipmentGroup,
      selectEquipmentGroup,
    };
  },
};
