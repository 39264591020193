
import { computed, defineComponent, onMounted, onUpdated, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import EquimpentSubtypesSelect from "@/components/catalogs-select/EquimpentSubtypesSelect.vue";
import FlagSelect from "@/components/catalogs-select/FlagSelect.vue";
import { useI18n } from "vue-i18n";
import { ElNotification } from "element-plus";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import EquimpentGroupSelect from "@/components/catalogs-select/EquimpentGroupSelect.vue";

interface FormCatalog {
  id?: number;
  equipment_subtype_id: string;
  equipment_group_id: string;
  name: string;
  size: string;
  manufacturing_materials: string;
  temperature?: number;
  note: string;
}

export default defineComponent({
  name: "EquipmentsForms",
  components: {
    EquimpentGroupSelect,
    Form,
    Field,
    EquimpentSubtypesSelect,
    ErrorMessage,
    FlagSelect,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const flagsData = computed(() => store.getters.SyncFlag);
    const entityId = ref("");
    const isLoading = ref(false);
    const addElement = ref(false);
    const tableData = ref<[] | any>([]);
    const tableDataM = ref<[] | any>([]);
    const measureTypeData = computed(() => store.getters.MeasureType);
    const measureUnitData = computed(() => store.getters.MeasureUnit);
    const officeData = computed(() => store.getters.Office);
    const inputOffice = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const measureType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const measureUnit = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const headerTable = ref([
      { column: t("synchronizeflagid"), key: "synchronize_flag_id" },
      { column: t("ioffice"), key: "office_id" },
      { column: t("externalcode"), key: "external_code" },
    ]);
    const headerTableM = ref([
      { column: t("imeasuretype"), key: "measure_type_id" },
      { column: t("imeasureunit"), key: "measure_unit_id" },
      { column: t("imeasure"), key: "measure" },
      { column: t("iremarks"), key: "remarks" },
    ]);
    const form = ref<FormCatalog>({
      id: undefined,
      equipment_subtype_id: "",
      equipment_group_id: "",
      name: "",
      size: "",
      manufacturing_materials: "",
      note: "",
    });

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    const getOffices = (offices) => {
      if (!officeData.value.length) {
        ApiService.get("/api/offices").then(({ data }) => {
          store.commit("setOfficeList", data.offices);
          inputOffice.value.list = data.offices;
          inputOffice.value.options = data.offices;
        });
      } else {
        inputOffice.value.list = officeData.value;
        inputOffice.value.options = officeData.value;
      }
      const offices_id = inputOffice.value.options.map((x) => x.id);
      offices.forEach((item, index) => {
        if (
          item !== null &&
          !offices_id.includes(item.id) &&
          inputOffice.value.options.length > 0
        ) {
          inputOffice.value.options.push(item);
          inputOffice.value.loading = false;
        }
      });
      store.commit("setOfficeList", inputOffice.value.options);
    };
    const selectOffice = (query) => {
      if (query !== "") {
        inputOffice.value.loading = true;
        setTimeout(() => {
          inputOffice.value.loading = false;
          inputOffice.value.options = inputOffice.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputOffice.value.options.length) {
            ApiService.query(`/api/offices/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.offices.forEach((o) => {
                store.commit("addOffice", o);
              });
              inputOffice.value.list = officeData.value;
              inputOffice.value.options = data.offices;
            });
          }
        }, 50);
      } else {
        inputOffice.value.options = officeData.value;
      }
    };

    const getMeasureType = (measure_type) => {
      if (!measureTypeData.value.length) {
        ApiService.get("/api/measure-types").then(({ data }) => {
          store.commit("setMeasureType", data);
          measureType.value.list = data;
          measureType.value.options = data;
        });
      } else {
        measureType.value.list = measureTypeData.value;
        measureType.value.options = measureTypeData.value;
      }
      const measure_type_id = measureType.value.options.map((x) => x.id);
      measure_type.forEach((item, index) => {
        if (
          item !== null &&
          !measure_type_id.includes(item.id) &&
          measureType.value.options.length > 0
        ) {
          measureType.value.options.push(item);
          measureType.value.loading = false;
        }
      });
    };
    const selectMeasureType = (query) => {
      if (query !== "") {
        measureType.value.loading = true;
        setTimeout(() => {
          measureType.value.loading = false;
          measureType.value.options = measureType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!measureType.value.options.length) {
            ApiService.query(`/api/measure-types/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.measure_types.forEach((item) => {
                store.commit("addMeasureType", item);
              });
              measureType.value.list = measureTypeData.value;
              measureType.value.options = data.measure_types;
            });
          }
        }, 50);
      } else {
        measureType.value.options = measureTypeData.value;
      }
    };

    const getMeasureUnit = () => {
      if (!measureUnitData.value.length) {
        ApiService.get("/api/measure-units").then(({ data }) => {
          store.commit("setMeasureUnit", data);
          measureUnit.value.list = data.measureUnit;
          measureUnit.value.options = data.measureUnit;
          store.commit("setLoadingStatus", false);
        });
      } else {
        measureUnit.value.list = measureUnitData.value;
        measureUnit.value.options = measureUnitData.value;
        store.commit("setLoadingStatus", false);
      }
    };
    const selectMeasureUnit = (query) => {
      if (query !== "") {
        measureUnit.value.loading = true;
        setTimeout(() => {
          measureUnit.value.loading = false;
          measureUnit.value.options = measureUnit.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!measureUnit.value.options.length) {
            ApiService.query(`/api/measure-units/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              data.measure_units.forEach((item) => {
                store.commit("addMeasureUnit", item);
              });
              measureUnit.value.list = measureUnitData.value;
              measureUnit.value.options = data.measure_units;
            });
          }
        }, 50);
      } else {
        measureUnit.value.options = measureUnitData.value;
      }
    };

    const updateMeasureUnit = (item) => {
      const measure = measureType.value.list.find(
        (x) => x.id === item.measure_type_id
      );
      measureUnit.value.options = measureUnitData.value.filter(
        (x) => x.measure_unit_type_id == measure.measure_unit_type_id
      );
    };

    const createSync = () => {
      addElement.value = true;
      if (tableData.value.length > 0) {
        let invalidTable = false;
        tableData.value.forEach((item, index) => {
          if (index === tableData.value.length - 1) {
            invalidTable =
              item.synchronize_flag_id === "" || item.external_code === "";
          }
        });
        if (!invalidTable) {
          addLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rflagtabletable"),
            type: "error",
          });
        }
      } else {
        addLine();
      }
    };

    const addLine = () => {
      tableData.value.push({
        synchronize_flag_id: "",
        office_id: "",
        external_code: "",
      });
    };

    const removeElement = (index) => {
      tableData.value.splice(index, 1);
    };

    const createMeasure = () => {
      if (tableDataM.value.length > 0) {
        let invalidTable = false;
        tableDataM.value.forEach((item, index) => {
          if (index === tableDataM.value.length - 1) {
            invalidTable =
              item.measure_type_id === "" ||
              item.measure_unit_id === "" ||
              item.measure === "";
          }
        });
        if (!invalidTable) {
          addLineM();
        } else {
          ElNotification({
            title: "Error",
            message: t("rmteditabletable"),
            type: "error",
          });
        }
      } else {
        addLineM();
      }
    };

    const addLineM = () => {
      tableDataM.value.push({
        id: null,
        measure_type_id: null,
        measure_unit_id: null,
        measure: "",
        remarks: "",
      });
    };

    const removeElementM = (index) => {
      tableDataM.value.splice(index, 1);
    };

    const createElement = async (payload) => {
      try {
        const resp = (await ApiService.post("/api/equipments", payload)).data;
        emit("success", resp);
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (payload) => {
      const paramsRoute = route.params;
      ApiService.put("/api/equipments/" + paramsRoute.id, payload)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        const payload = {
          id: form.value.id,
          equipment_subtype_id: form.value.equipment_subtype_id,
          equipment_group_id: form.value.equipment_group_id,
          name: form.value.name,
          size: form.value.size,
          manufacturing_materials: form.value.manufacturing_materials,
          note: form.value.note,
          temperature: form.value.temperature,
          iso: [],
          synchronize_flags: tableData.value,
          equipment_measures: tableDataM.value,
        };
        if (form.value.id) {
          updateElement(payload);
        } else {
          createElement(payload);
        }
        router.push({ name: "equipments" });
      }
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(async () => {
      console.clear();
      store.commit("setLoadingStatus", true);
      setCurrentPageBreadcrumbs("equipments", [
        { route: "/equipments-catalogs", label: "equipments" },
        { route: "/equipmentsCatalogList", label: "equipment" },
      ]);
      store.commit("setLoading", false);
      await store.dispatch("loadElements");
      const paramsRoute = route.params;
      if (Object.keys(paramsRoute).length > 0 && paramsRoute.id) {
        store.commit("setLoading", false);
        ApiService.get("/api/equipments/" + paramsRoute.id).then(({ data }) => {
          form.value = { ...data };
          if (data.synchronize_equipment) {
            data.synchronize_equipment.forEach((x) => {
              tableData.value.push({
                synchronize_flag_id: x.synchronize_flag_id,
                synchronize_flag: x.flagsynchronize,
                office_id: x.office_id,
                external_code: x.external_code,
              });
            });
          }
          if (data.equipment_measures) {
            tableDataM.value = [...data.equipment_measures];
          }
          getOffices(data.synchronize_equipment.map((x) => x.office));
          getMeasureType(data.equipment_measures.map((x) => x.measure_type));
        });
      }
      getMeasureUnit();
    });

    const flagsDataFunction = (flag_synchronize) => {
      let flagsDataIds = flagsData.value.map((x) => x.id);
      if (flag_synchronize && !flagsDataIds.includes(flag_synchronize.id)) {
        store.commit("addSyncFlag", flag_synchronize);
      }
      return flagsData.value;
    };

    return {
      submitButton,
      form,
      isLoading,
      addElement,
      tableData,
      tableDataM,
      headerTable,
      headerTableM,
      measureType,
      measureUnit,
      inputOffice,
      flagsDataFunction,
      onSumbit,
      isNameRequired,
      updateElement,
      createElement,
      createSync,
      removeElement,
      createMeasure,
      removeElementM,
      selectOffice,
      selectMeasureType,
      selectMeasureUnit,
      updateMeasureUnit,
    };
  },
});
